<template>
    <transition name="fade-in-up">
      <router-view></router-view>
    </transition>
</template>

<style lang="scss">
</style>

<script>

export default {
  components: {}
};
</script>
